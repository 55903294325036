import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { queryKeys, usePutAppState } from '../../customHooks/useAppState';

const PANEL_ID = 'panelId';

export const PanelsDropdown = props => {
  const { panels } = props;

  const [selectedPanelId, setSelectedPanelId] = useState();

  useEffect(() => {
    if (panels?.length) {
      const panelId = sessionStorage.getItem(PANEL_ID);
      const panel = (panelId && panels.find(panel => panel.id === panelId)) || panels[0];
      setSelectedPanelId(panel.id);
    }
  }, [panels]);

  usePutAppState(
    queryKeys.selectedPanel,
    panels.find(panel => panel.id === selectedPanelId)
  );

  const setPanelId = panelId => {
    sessionStorage.setItem(PANEL_ID, panelId);
    setSelectedPanelId(panelId);
  };

  const sortedPanels = panels.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));

  return (
    <div className="client-select me-3">
      {panels.length > 1 && (
        <Input
          type="select"
          value={selectedPanelId}
          onChange={e => setPanelId(e.target.value)}
          style={{ color: 'initial' }}
          className="form-select"
        >
          {sortedPanels.map(panel => (
            <option key={panel.id} value={panel.id}>
              {panel.name}
            </option>
          ))}
        </Input>
      )}
      {panels.length === 1 && <span>{panels[0].name}</span>}
    </div>
  );
};
