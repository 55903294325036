import React from 'react';
import intl from 'react-intl-universal';
import { Icons } from '../../../../components/icons/Icons';

export const CardStats = props => {
  return (
    <div className={props.className}>
      <div style={{ marginTop: '0.5rem' }}>
        <Icons.CheckIcon style={{ color: 'green' }} className="me-2" />
        <span>{intl.get('app.complete')}:</span>
        <span className="stats-detail-number">{props.completed}</span>
      </div>
      <div style={{ marginTop: '0.5rem' }}>
        <Icons.BanIcon style={{ color: 'red' }} className="me-2" />
        <span>{intl.get('app.disqualified')}:</span>
        <span className="stats-detail-number">{props.terminated}</span>
      </div>
      <div style={{ marginTop: '0.5rem' }}>
        <Icons.FilterIcon style={{ color: 'orange' }} className="me-2" />
        <span>{intl.get('app.overQuota')}:</span>
        <span className="stats-detail-number">{props.overQuota}</span>
      </div>
    </div>
  );
};
