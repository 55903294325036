import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import intl from 'react-intl-universal';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Cookies from 'js-cookie';
import { localtime, Fonts, InvokeCSS } from 'webapp-common';
import { auth } from './api/Auth';
import appConfig from './appConfig';
import { locale as en_US } from './locales/en-US';
import reportWebVitals from './reportWebVitals';
import { App } from './containers/app/App';

import './index.css';

const locales = {
  'en-US': en_US
};

if (!Cookies.get(appConfig.offsetCookieName)) {
  Cookies.set(appConfig.offsetCookieName, localtime.getOffset());
}
if (!Cookies.get(appConfig.timezoneCookieName)) {
  Cookies.set(appConfig.timezoneCookieName, localtime.getTimezoneName());
}

// Sentry.init({
//   dsn: process.env.SENTRY_DSN,
//   release: process.env.SENTRY_RELEASE,
//   maxBreadcrumbs: process.env.SENTRY_MAX_BREADCRUMBS,
//   tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
//   environment: process.env.SENTRY_ENVIRONMENT,
//   integrations: [new Integrations.BrowserTracing()]
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false
    }
  }
});

const authValidator = () => {
  if (auth.isAuthenticated()) {
    setTimeout(authValidator, 1000);
  } else {
    auth.logout();
  }
};

setTimeout(authValidator, 1000);

// Init i18n. We currently only support en-US.
intl
  .init({
    currentLocale: 'en-US',
    locales
  })
  .then(() => {
    ReactDOM.render(
      <BrowserRouter basename="/p">
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <InvokeCSS />
            <Fonts />
            <App />
          </QueryClientProvider>
        </React.StrictMode>
      </BrowserRouter>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
