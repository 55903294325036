import React, { useReducer, useEffect } from 'react';
import { useQuery } from 'react-query';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { debounce } from 'lodash';
import { localtime, toast, InvokeTable, Loader, SearchInput } from 'webapp-common';
import { queryKeys, useGetAppState } from '../../../../customHooks/useAppState';
import { fetchProjectParticipants } from '../../../../api/participantsApi';

const reducer = (state, payload) => ({ ...state, ...payload });

export const ProjectParticipants = props => {
  const panelId = useGetAppState(queryKeys.selectedPanel)?.panelId;
  const { collectionIds, sessionId, sessionName } = props;
  const [state, setState] = useReducer(reducer, {
    participantListColumns: [],
    showViewParticipantModal: false
  });
  const [queryParams, setQueryParams] = useReducer(reducer, {
    screener: true,
    pageSize: 10,
    pageNum: 1,
    searchString: '',
    sortField: '',
    sortOrder: 'asc',
    activeOnly: true,
    panelId
  });
  const { data = {}, isLoading } = useQuery(
    ['project-participants', collectionIds[0], queryParams],
    () => fetchProjectParticipants(collectionIds[0], queryParams),
    {
      enabled: !!queryParams.panelId && collectionIds.length !== 0,
      onError: () => toast.error({ text: intl.get('app.fetchProjectError') })
    }
  );

  const { columnOrder, enrolleeDataDef = {}, participantList = { content: [] } } = data?.payload || {};
  const { keyTransformMap } = enrolleeDataDef;

  const getColumns = (columnOrder, keyTransformMap) => {
    if (!columnOrder || columnOrder.length === 0) {
      return [];
    }
    return columnOrder.map(key => ({
      dataField: `metadata.dataStore.${key}`,
      text: (keyTransformMap && keyTransformMap[key]) || key,
      headerFormatter: formatHeader,
      sort: true
    }));
  };

  useEffect(() => {
    if (panelId) {
      setQueryParams({ panelId });
    }
  }, [panelId]);

  useEffect(() => {
    if (columnOrder) {
      setState({ participantListColumns: getColumns(columnOrder, keyTransformMap) });
    }
  }, [columnOrder]);

  const toggleDisqualified = e => {
    setQueryParams({
      activeOnly: !e.target.checked
    });
  };

  const sort = (sortField, sortOrder) => {
    setQueryParams({ sortField, sortOrder });
  };

  const paginate = (page, sizePerPage, sortField, sortOrder) => {
    setQueryParams({ page, sizePerPage, sortField, sortOrder });
  };

  const searchParticipantsDebounced = debounce(value => {
    setQueryParams({ searchString: value });
  }, 500);

  const handleSearchChange = e => {
    searchParticipantsDebounced(e.target.value);
  };

  const formatHeader = (col, colIndex, components) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="text-ellipsis me-3" title={col.text}>
          {col.text}
        </span>
        <span>{components.sortElement}</span>
      </div>
    );
  };

  const toggleViewParticipantModal = () => {
    setState({ showViewParticipantModal: !state.showViewParticipantModal });
  };

  const rowEvents = {
    onClick: (e, row) => {
      setState({
        selectedRow: row
      });
      if (Object.keys(row).length !== 0 || row.constructor !== Object) {
        toggleViewParticipantModal();
      }
    }
  };

  const timeStamp = localtime.getFormattedDateCap(Date.now());

  return (
    <div className="project-participants mt-3">
      {isLoading && <Loader fullScreen spinner />}
      <div className="ms-2 mb-3">
        <a
          href={`/a/api/exportScreenerParticipantsCSV/${sessionId}/${queryParams.activeOnly}`}
          download={`${sessionName}_${timeStamp}.csv`}
          className="btn btn-secondary"
        >
          {intl.get('app.export')}
        </a>
      </div>
      <div className="mx-3">
        <label className="mb-3">
          <Input type="checkbox" checked={!queryParams.activeOnly} onChange={toggleDisqualified} />
          <span className="ms-3">{intl.get('app.includeDisqualifiedParticipants')}</span>
        </label>
        <SearchInput placeholder={intl.get('app.searchParticipants')} onChange={handleSearchChange} className="mb-3" />
        {state.participantListColumns.length > 0 && (
          <InvokeTable
            keyField="id"
            columns={state.participantListColumns}
            pagedList={participantList}
            enablePagination
            paginate={paginate}
            sort={sort}
            rowEvents={rowEvents}
          />
        )}
      </div>
    </div>
  );
};
