import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Cookies from 'js-cookie';
import { toast, Loader, NavTab } from 'webapp-common';
import appConfig from '../../../../appConfig';
import { fetchSessionStatsForProject } from '../../../../api/projectsApi';
import { ProjectSummary } from './ProjectSummary';
import { ProjectParticipants } from './ProjectParticipants';
import ProjectResponses from './projectResponses';

const SUMMARY_TAB = 'summary';
const PARTICIPANTS_TAB = 'participants';
const RESPONSES_TAB = 'responses';

export const ProjectPage = props => {
  const { active } = props;

  const { id, tab: projectsTab, subtab: activeTab = SUMMARY_TAB } = useParams();

  const { isLoading, data = {} } = useQuery(['single-session-stats', id], () => fetchSessionStatsForProject(id), {
    onError: () => toast.error({ text: intl.get('app.fetchProjectError') })
  });
  const { clientId, responseRates, collectionIds, sessionId, sessionName } = data?.payload || {};

  useEffect(() => {
    const clientIdCookie = Cookies.get(appConfig.clientIdCookieName);
    if (clientId && clientId !== clientIdCookie) {
      Cookies.set(appConfig.clientIdCookieName, clientId);
    }
    return () => Cookies.remove(appConfig.clientIdCookieName);
  }, [clientId]);

  const getUrl = tab => {
    return (active && `${appConfig.activeProjectsPath}/${id}/${tab}`) || `${appConfig.closedProjectsPath}/${id}/${tab}`;
  };

  return (
    <>
      <section className="left-sub-nav">
        <NavTab
          icon="fa-arrow-left"
          iconStyle="light"
          label={intl.get('app.backToProjects')}
          to={`${appConfig.projectsPath}/${projectsTab}`}
        />
        <NavTab
          icon="fa-clipboard-list-check"
          iconStyle="light"
          size={2}
          label={intl.get('app.summary')}
          active={activeTab === SUMMARY_TAB}
          to={getUrl(SUMMARY_TAB)}
        />
        <NavTab
          icon="fa-users"
          iconStyle="light"
          size={2}
          label={intl.get('app.participants')}
          active={activeTab === PARTICIPANTS_TAB}
          to={getUrl(PARTICIPANTS_TAB)}
        />
        <NavTab
          icon="fa-poll-people"
          iconStyle="light"
          size={2}
          label={intl.get('app.responses')}
          active={activeTab === RESPONSES_TAB}
          to={getUrl(RESPONSES_TAB)}
        />
      </section>
      {isLoading && <Loader fullScreen spinner />}
      <div className="body-container l3-bg">
        <div style={{ fontWeight: '600', marginBottom: '1rem' }}>
          {intl.get('app.project')}: {sessionName}
        </div>
        {activeTab === SUMMARY_TAB && <ProjectSummary responseRates={responseRates} />}
        {activeTab === PARTICIPANTS_TAB && collectionIds && (
          <ProjectParticipants collectionIds={collectionIds} sessionId={sessionId} sessionName={sessionName} />
        )}
        {activeTab === RESPONSES_TAB && <ProjectResponses responseRates={responseRates} sessionId={sessionId} />}
      </div>
    </>
  );
};
