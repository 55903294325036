import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { jsUtil, InvokeModal } from 'webapp-common';

import './AddEditUserModal.css';

export const AddEditUserModal = props => {
  const { selectedPanel } = props;
  const [user, setUser] = useState(props.user);

  const handleDelete = () => {
    if (window.confirm(intl.get('app.user.remove.confirm', { email: user.email, panel: selectedPanel.name }))) {
      user.userPermissions = user.userPermissions.filter(perm => perm.panelId !== selectedPanel.id);
      props.saveUser(user);
    }
  };

  const handleEmailChange = e => {
    const email = e.target.value.trim();
    setUser({
      ...user,
      email
    });
  };

  const isSaveEnabled = () => {
    if (!user.email || !jsUtil.validateEmail(user.email)) {
      return false;
    }
    return props.user.email !== user.email;
  };

  const saveUser = () => {
    const copy = { ...user };
    copy.userName = copy.userName || copy.email;
    props.saveUser(copy);
  };

  return (
    <InvokeModal
      className="user-edit-modal"
      showModal
      toggle={props.toggle}
      modalTitle={intl.get('app.addEditUser')}
      enableDelete
      deleteButtonText={intl.get('app.remove')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      delete={handleDelete}
      save={saveUser}
      enableSave={isSaveEnabled()}
      backdrop={false}
      keyboard={false}
    >
      <div>
        <div>{intl.get('app.emailAddress')}</div>
        <div>
          <Input value={user.email || ''} onChange={handleEmailChange} />
        </div>
      </div>
    </InvokeModal>
  );
};
