import React from 'react';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

const UNAUTHORIZED = 'unauthorized';

export const LoginForm = props => {
  const { onLogin, queries } = props;
  const { authError } = queries || {};

  const isPasswordResetSuccess = () => {
    // const { email, success, message } = queries || {};
    // return !!(email && success === 'true' && message);
    return false;
  };

  const getHeaderTitleAndMessage = () => {
    let titleKey = '';
    let msgKey = '';
    if (authError === UNAUTHORIZED) {
      titleKey = 'app.accountDisabled';
      msgKey = 'app.accountDisabled.message';
    } else if (authError) {
      titleKey = 'app.loginFailed';
      msgKey = 'app.loginFailed.message';
    } else if (isPasswordResetSuccess()) {
      titleKey = 'app.login.passwordReset';
      msgKey = 'app.login.passwordReset.message';
    }
    return {
      title: (titleKey && intl.get(titleKey)) || '',
      msg: (msgKey && intl.get(msgKey)) || ''
    };
  };

  const getPageHeader = () => {
    const titleAndMsg = getHeaderTitleAndMessage();
    if (!titleAndMsg.title) {
      return null;
    }
    return (
      <div className="align-center">
        <h5 className="welcome-text">{titleAndMsg.title}</h5>
        {titleAndMsg.msg && <div className="support-message mb-5">{titleAndMsg.msg}</div>}
      </div>
    );
  };

  return (
    <div style={{ height: 'inherit', verticalAlign: 'middle' }}>
      <h1 style={{ color: 'var(--gray-700)', fontSize: '4rem', margin: '0 0 0.5rem 0' }}>
        {intl.get('app.panelPortal')}
      </h1>
      {getPageHeader()}
      <Button color="primary" onClick={onLogin} style={{ padding: '0 0.75rem' }}>
        {intl.get('app.login')}
      </Button>
    </div>
  );
};
