export const locale = {
  'app.panelPortal': 'Panel Portal',
  'app.logo': 'Logo',
  'app.login': 'Login',
  'app.logout': 'Logout',
  'app.project': 'Project',
  'app.projects': 'Projects',
  'app.admin': 'Admin',
  'app.loading': 'Loading ...',
  'app.nodata': 'No data',
  'app.save': 'Save',
  'app.cancel': 'Cancel',
  'app.remove': 'Remove',
  'app.email': 'Email',
  'app.emailAddress': 'Email address',
  'app.lastLogin': 'Last Login',
  'app.accountDisabled': 'Account is Disabled',
  'app.accountDisabled.message': 'Your account is disabled, please contact MarketCast Support.',
  'app.loginFailed': 'Login Failed',
  'app.loginFailed.message': 'Try again or contact MarketCast Support - support@invoke.com',
  'app.login.passwordReset': 'Password Reset Successful',
  'app.login.passwordReset.message': 'Please log in with your new password',
  'app.access.denied': 'Access Denied',
  'app.access.denied.message': 'You do not have permission to view this page',
  'app.users': 'Users',
  'app.addUser': 'Add User',
  'app.addEditUser': 'Add/Edit User',
  'app.fetchUsersError': 'Error fetching users',
  'app.user.saved': 'User saved!',
  'app.user.remove.confirm': 'Are you sure you want to remove user {email} from the {panel} panel?',
  'app.panels': 'Panels',
  'app.session.state.OPEN': 'Active',
  'app.session.state.TEST': 'Test',
  'app.session.state.PENDING': 'Pending',
  'app.session.state.PAUSED': 'Paused',
  'app.session.state.CLOSED': 'Closed',
  'app.pending': 'Pending',
  'app.test': 'Test',
  'app.active': 'Active',
  'app.closed': 'Closed',
  'app.search': 'Search',
  'app.ir': 'IR',
  'app.eIr': 'Exp IR',
  'app.target': 'Target',
  'app.complete': 'Complete',
  'app.disqualified': 'Disqualified',
  'app.overQuota': 'Over Quota',
  'app.name': 'Name',
  'app.status': 'Status',
  'app.createDate': 'Create Date',
  'app.fetchProjectsError': 'Error fetching projects',
  'app.fetchProjectError': 'Error fetching project',
  'app.backToProjects': 'Back to Projects',
  'app.summary': 'Summary',
  'app.participants': 'Participants',
  'app.responses': 'Responses',
  'app.segments': 'Segments',
  'app.export': 'Export',
  'app.includeDisqualifiedParticipants': 'Include disqualified participants',
  'app.searchParticipants': 'Search Participants',
  'app.responses.fetchJoinersError': 'Error fetching questions for project',
  'app.fetchResponseDataError': 'Error fetching participants answers',
  'app.userId': 'User ID',
  'app.response': 'Response',
  'app.wordCount': 'Word Count',
  'app.answer': 'Answer',
  'app.questionType.multi': 'Choice',
  'app.questionType.matrix': 'Matrix',
  'app.questionType.open': 'Open Text',
  'app.questionType.text': 'Text Stim',
  'app.questionType.image': 'Image Stim',
  'app.questionType.video': 'Video Stim',
  'app.questionType.ranked': 'Ranked',
  'app.questionType.standAloneText': 'Stand-alone Text',
  'app.questionType.conceptRotation': 'Concept Rotation',
  'app.questionType.concept': 'Concept',
  'app.questionType.thirdparty': 'Third Party',
  'app.questionType.dialsmith': 'Dialsmith',
  'app.questionType.dialSmithVideo': 'Dialsmith Video',
  'app.questionType.webcontent': 'Web Content',
  'app.questionType.page': 'Page',
  'app.questionType.virtualFocusGroup': 'Vis-a-Vis'
};
