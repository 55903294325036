import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { jsUtil, Loader } from 'webapp-common';
import appConfig from '../../../appConfig';
import { auth } from '../../../api/Auth';

export const CallbackPage = () => {
  const history = useHistory();

  useEffect(() => {
    auth
      .handleAuthentication()
      .then(() => {
        const queries = jsUtil.getUrlQueries();
        window.location = queries?.r
          ? `${appConfig.invokeAuthCallbackURL}?r=${queries.r}`
          : `${appConfig.invokeAuthCallbackURL}?r=${appConfig.userDashboardPage}`;
      })
      .catch(err => history.push(`${appConfig.loginPage}?authError=${err.error}`));
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <Loader />
    </div>
  );
};
