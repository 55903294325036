import { fetcher } from 'webapp-common';

const SESSIONS_API = '/a/api/sessions';
const SESSION_STATS_URL = '/a/api/sessionStatistics';
const SURVEYS_API = '/a/api/surveys';
const RESPONSE_DATA_API = '/a/api/screenerResponseData';

export const fetchSessionStats = data => fetcher.post(SESSION_STATS_URL, data);

export const fetchSessionStatsForProject = id => fetcher.get(`${SESSION_STATS_URL}/${id}`);

export const fetchSession = sessionId => fetcher.get(`${SESSIONS_API}/${sessionId}`);

export const fetchSurvey = surveyId => fetcher.get(`${SURVEYS_API}/${surveyId}`);

export const fetchResponseData = params => fetcher.post(RESPONSE_DATA_API, params);
