// eslint-disable-next-line import/no-anonymous-default-export
const appConfig = {
  // Authentication will cause a redirect to this page
  userDashboardPage: process.env.REACT_APP_DASHBOARD_PAGE,

  pathPrefix: '/p',
  loginPage: '/login',
  logoutPage: '/logout',
  projectPagePath: '/project',
  projectsPath: '/projects',
  activeProjectsPath: '/projects/active',
  closedProjectsPath: '/projects/closed',
  adminPagePath: '/admin',
  adminUsersPath: '/admin/users',
  adminPanelsPath: '/admin/panels',

  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0DB: process.env.REACT_APP_AUTH0_DB,
  auth0CallbackURL: process.env.REACT_APP_AUTH0_CALLBACKURL,
  invokeAuthCallbackURL: process.env.REACT_APP_INVOKE_AUTH_CALLBACKURL,

  enableReduxDevTools: true,

  authCookieName: 'VTOKEN',
  offsetCookieName: 'offset',
  clientIdCookieName: 'ppClientId',
  timezoneCookieName: 'timezoneName',
  userSessionMaxIdleMinutes: 15 // same value as lewis.userSessionMaxIdleMinutes on the back-end
};

export default appConfig;
