import React from 'react';
import intl from 'react-intl-universal';
import appConfig from '../../../appConfig';
import { NavTab } from 'webapp-common';

export const AdminLeftNav = props => {
  const { activeTab } = props;

  return (
    <section className="left-nav">
      <NavTab
        icon="fa-users"
        iconStyle="solid"
        label={intl.get('app.users')}
        active={activeTab === 'users'}
        to={appConfig.adminUsersPath}
      />
      <NavTab
        icon="fa-users-cog"
        iconStyle="solid"
        label={intl.get('app.panels')}
        active={activeTab === 'panels'}
        to={appConfig.adminPanelsPath}
      />
    </section>
  );
};
