import React from 'react';
import intl from 'react-intl-universal';

export const IrLabel = props => {
  return (
    <label
      style={{
        backgroundColor: props.backgroundColor,
        border: '2px solid gray',
        padding: '0.25rem 0.5rem',
        fontWeight: '600',
        fontSize: props.size || 'inherit',
        height: 'fit-content'
      }}
    >
      <span>{intl.get('app.ir')}:</span>
      <span className="ms-3">{`${props.rate}%`}</span>
      {props.target && (
        <div style={{ fontWeight: 'initial', fontSize: props.targetSize || 'inherit' }}>
          {`${intl.get('app.target')}: ${props.target}%`}
        </div>
      )}
    </label>
  );
};
