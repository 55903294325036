import React from 'react';
import { Nav, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import appConfig from '../../../appConfig';
import { auth } from '../../../api/Auth';
import { Logo } from 'webapp-common';
import { PanelsDropdown } from '../../../components/panelsDropdown/PanelsDropdown';

const USER_PICTURE = 'user_picture';

export const PageHeader = props => {
  const { user, panels, isAuthenticated } = props;
  const { email } = user || {};
  const avatar = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(USER_PICTURE) : null;

  const logout = () => auth.logout();

  return (
    <div className="page-header">
      <div className="logo-and-nav-links">
        <Logo />
        {isAuthenticated && (
          <div>
            <Nav navbar style={{ flexDirection: 'row' }}>
              <li key="projects">
                <NavLink to={appConfig.projectsPath} activeClassName="isSelected">
                  {intl.get('app.projects')}
                </NavLink>
              </li>
              {user?.isSuperUser && (
                <li key="admin">
                  <NavLink to={appConfig.adminPagePath} activeClassName="isSelected">
                    {intl.get('app.admin')}
                  </NavLink>
                </li>
              )}
            </Nav>
          </div>
        )}
      </div>
      <div className="no-list-style client-nav-section">
        {isAuthenticated && (
          <div>
            <PanelsDropdown panels={panels} />
            <span>{email}</span>
            <Button style={{ background: 'none', border: 'none', paddingTop: 0 }} onClick={logout}>
              <img className="user-picture" src={avatar} alt="" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
