import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { useQuery, useMutation } from 'react-query';
import { Button } from 'reactstrap';
import { toast, InvokeTable, Loader } from 'webapp-common';
import { queryKeys, useGetAppState } from '../../../../customHooks/useAppState';
import { getUsers, createUser, updateUser } from '../../../../api/usersApi';
import { AddEditUserModal } from './AddEditUserModal';

import './AdminUsers.css';

const reducer = (state, payload) => ({ ...state, ...payload });

function getNewUserObject(panelId) {
  return {
    isActive: true,
    userPermissions: [{ panelId }],
    app: 'PanelPortal'
  };
}

export const AdminUsers = props => {
  const selectedPanel = useGetAppState(queryKeys.selectedPanel);

  const [state, setState] = useReducer(reducer, {
    selectedUser: null
  });

  const [queryParams, setQueryParams] = useReducer(reducer, {
    active: true,
    panelId: selectedPanel?.id,
    pageNum: 1,
    pageSize: 10,
    sortField: 'email',
    sortOrder: 'asc'
  });

  useEffect(() => {
    if (selectedPanel) {
      setQueryParams({ panelId: selectedPanel.id });
    }
  }, [selectedPanel]);

  const usersQuery = useQuery(['users', queryParams], () => getUsers(queryParams), {
    enabled: !!queryParams.panelId,
    onError: () => toast.error({ text: intl.get('app.fetchUsersError') })
  });

  const createUserMutation = useMutation(user => createUser(user), {
    onError: error => toast.error({ text: error.errorMessage }),
    onSuccess: onUserMutateSuccess
  });

  const updateUserMutation = useMutation(user => updateUser(user), {
    onError: error => toast.error({ text: error.errorMessage }),
    onSuccess: onUserMutateSuccess
  });

  function onUserMutateSuccess() {
    closeAddEditUserModal();
    toast.success({ text: intl.get('app.user.saved') });
    usersQuery.refetch();
  }

  function setSelectedUser(user) {
    setState({ selectedUser: user });
  }

  function closeAddEditUserModal() {
    setState({ selectedUser: null });
  }

  function sort(sortField, sortOrder) {
    setQueryParams({ sortField, sortOrder });
  }

  function paginate(pageNum, pageSize, sortField, sortOrder) {
    setQueryParams({ pageNum, pageSize, sortField, sortOrder });
  }

  function saveUser(user) {
    if (!user.userID) {
      createUserMutation.mutate(user);
    } else {
      updateUserMutation.mutate(user);
    }
  }

  function formatEmail(email, user) {
    return (
      <span className="link" title={email} onClick={() => setSelectedUser(user)}>
        {email}
      </span>
    );
  }

  function formatLastLogin(millis) {
    if (!millis) {
      return '';
    }
    const date = Intl.DateTimeFormat([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(millis);
    const time = Intl.DateTimeFormat([], {
      hour: '2-digit',
      minute: '2-digit'
    }).format(millis);
    return `${date} ${time}`;
  }

  function getColumns() {
    return [
      {
        dataField: 'email',
        text: intl.get('app.email'),
        headerStyle: { width: '18rem' },
        classes: 'text-truncate',
        formatter: formatEmail,
        sort: true
      },
      {
        dataField: 'lastLogin',
        text: intl.get('app.lastLogin'),
        headerStyle: { width: '12rem' },
        formatter: formatLastLogin,
        sort: true
      }
    ];
  }

  const showLoader = usersQuery.isFetching || createUserMutation.isLoading || updateUserMutation.isLoading;

  return (
    <section className="body-container l2-bg admin-users">
      {showLoader && <Loader spinner fullScreen />}
      <Button
        className="add-user-button"
        color="primary"
        onClick={() => setSelectedUser(getNewUserObject(selectedPanel.id))}
      >
        {intl.get('app.addUser')}
      </Button>
      <div className="users-table">
        <InvokeTable
          keyField="userID"
          pagedList={usersQuery.data || {}}
          columns={getColumns()}
          sort={sort}
          enablePagination={true}
          paginate={paginate}
        />
      </div>
      {state.selectedUser && (
        <AddEditUserModal
          user={state.selectedUser}
          selectedPanel={selectedPanel}
          saveUser={saveUser}
          toggle={closeAddEditUserModal}
        />
      )}
    </section>
  );
};
