import React from 'react';
import { ProjectsLeftNav } from './ProjectsLeftNav.js';
import { Projects } from './Projects';

const ACTIVE_TAB = 'active';
const CLOSED_TAB = 'closed';

export const ProjectsHome = props => {
  const activeTab = props.routeProps.match.params.tab || ACTIVE_TAB;

  return (
    <section className="app-body">
      <ProjectsLeftNav activeTab={activeTab} />
      {activeTab === ACTIVE_TAB && <Projects routeProps={props.routeProps} active />}
      {activeTab === CLOSED_TAB && <Projects routeProps={props.routeProps} />}
    </section>
  );
};
