import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { jsUtil } from 'webapp-common';
import appConfig from '../../../appConfig';
import { auth } from '../../../api/Auth';
import { LoginForm } from '../../../components/loginForm/LoginForm';

export const LoginPage = ({ doLogout }) => {
  const history = useHistory();

  // Capture the queries from the first render
  const queries = jsUtil.getUrlQueries();
  const queriesRef = useRef(Object.keys(queries).length !== 0 ? queries : null);

  useEffect(() => {
    if (doLogout) {
      auth.logout();
    }
  }, [doLogout]);

  const login = () => auth.login(queries);

  const authToken = auth.getAuthToken();
  const isAuthenticated = auth.isAuthenticated(false);

  if (!doLogout && authToken && isAuthenticated) {
    history.push(appConfig.projectsPath);
    return null;
  }

  return (
    <div className="welcome-page app-bg">
      <div style={{ textAlign: 'center' }}>
        <LoginForm onLogin={login} queries={queriesRef.current} />
      </div>
    </div>
  );
};
