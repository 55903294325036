import React, { useState, useReducer, useRef } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { localtime, toast, InvokeTable, Loader, SearchInput } from 'webapp-common';
import appConfig from '../../../appConfig';
import { fetchSessionStats } from '../../../api/projectsApi';
import { queryKeys, useGetAppState } from '../../../customHooks/useAppState';
import { Icons } from '../../../components/icons/Icons';
import { ProjectCard } from './projectCard/ProjectCard';
import { ProjectPage } from './projectPage/ProjectPage';

import './Projects.css';

const PROJECTS_VIEW = 'projects_view';
const LIST_VIEW = 'list';
const CARD_VIEW = 'card';
const getViewStyle = () => localStorage.getItem(PROJECTS_VIEW);
const reducer = (state, payload) => ({ ...state, ...payload });
const getDisplayTime = time => localtime.getFormattedDateCap(time);

export const Projects = props => {
  const { routeProps, active } = props;
  const tabParams = routeProps?.match?.params || {};
  const { id: projectId } = tabParams;
  const panelId = useGetAppState(queryKeys.selectedPanel)?.panelId;
  const [queryParams, setQueryParams] = useReducer(reducer, {
    screener: true,
    sessionStates: (active && ['OPEN', 'PAUSED', 'PENDING', 'TEST']) || ['CLOSED'],
    pageSize: 10,
    pageNum: 1,
    searchString: '',
    sortField: '',
    sortOrder: ''
  });
  const history = useHistory();
  const [viewStyle, setViewStyle] = useState(getViewStyle() || CARD_VIEW);
  const statsRef = useRef();

  const { isLoading, data = {} } = useQuery(
    ['session-stats', panelId, queryParams],
    () => fetchSessionStats({ panelId, ...queryParams }),
    {
      enabled: !!panelId,
      onSuccess: data => (statsRef.current = { content: getProjectStats(data?.content) || [] }),
      onError: () => toast.error({ text: intl.get('app.fetchProjectsError') })
    }
  );

  const getProjectStats = content => {
    const rows = [];
    content?.forEach((row, i) => {
      const {
        id,
        sessionName,
        sessionState,
        sessionCreateDate,
        responseRates: { panelStats = [], panelQuotas = [] }
      } = row || {};
      const stats = panelStats.find(p => p.panelId === panelId);
      const quotas = panelQuotas.find(q => q.panelId === panelId);
      rows.push({
        sessionName,
        sessionState,
        sessionCreateDate: getDisplayTime(sessionCreateDate),
        expectedIR: quotas?.expectedIR,
        incidenceRate: stats?.incidenceRate,
        completed: stats?.completed,
        terminated: stats?.terminated,
        overQuota: stats?.overQuota,
        rowNumber: i,
        id
      });
    });
    return rows;
  };

  const searchProjectsDebounced = debounce(value => {
    setQueryParams({ searchString: value });
  }, 500);

  const handleSearchChange = e => {
    searchProjectsDebounced(e.target.value);
  };

  const setLocalViewStyle = style => {
    localStorage.setItem(PROJECTS_VIEW, style);
    setViewStyle(style);
  };

  const gotoUrl = id => {
    const path = (active && appConfig.activeProjectsPath) || appConfig.closedProjectsPath;
    history.push(`${path}/${id}`);
  };

  const getProjectCards = () => {
    return data?.content?.map(project => {
      const displayTime = getDisplayTime(project.sessionCreateDate);
      return (
        <ProjectCard
          name={project.sessionName}
          status={project.sessionState}
          responseRates={project.responseRates}
          displayTime={displayTime}
          panelId={panelId}
          key={project.id}
          gotoUrl={() => gotoUrl(project.id)}
        />
      );
    });
  };

  const sort = (sortField, sortOrder) => {
    setQueryParams({ sortField, sortOrder, pageNum: 1 });
  };

  const paginate = pageNum => {
    setQueryParams({ pageNum });
  };

  const formatNameCell = (name, row) => {
    return (
      <div style={{ color: '#0d6efd', cursor: 'pointer' }} onClick={() => gotoUrl(row.id)}>
        {name}
      </div>
    );
  };

  const formatStatusCell = status => {
    if (status === 'OPEN') {
      return intl.get('app.active');
    }
    return intl.get(`app.${status.toLowerCase()}`);
  };

  const formatIR = ir => (ir && `${ir}%`) || '';

  const statsColumns = [
    {
      dataField: 'sessionName',
      text: intl.get('app.name'),
      headerStyle: { width: '12rem' },
      formatter: formatNameCell,
      classes: 'text-ellipsis',
      sort: true
    },
    {
      dataField: 'incidenceRate',
      text: intl.get('app.ir'),
      formatter: formatIR
    },
    {
      dataField: 'expectedIR',
      text: intl.get('app.eIr'),
      formatter: formatIR
    },
    {
      dataField: 'sessionState',
      text: intl.get('app.status'),
      formatter: formatStatusCell,
      style: { textTransform: 'capitalize' }
    },
    {
      dataField: 'sessionCreateDate',
      text: intl.get('app.createDate'),
      sort: true
    },
    {
      dataField: 'completed',
      text: intl.get('app.complete'),
      sort: true
    },
    {
      dataField: 'terminated',
      text: intl.get('app.disqualified'),
      sort: true
    },
    {
      dataField: 'overQuota',
      text: intl.get('app.overQuota'),
      sort: true
    }
  ];

  const getProjects = () => {
    return (
      <InvokeTable
        keyField="rowNumber"
        loadingRequested={isLoading}
        pagedList={statsRef.current}
        columns={statsColumns}
        sort={sort}
        enablePagination
        paginate={paginate}
      />
    );
  };

  return (
    <>
      {isLoading && <Loader fullScreen spinner />}
      {!projectId && (
        <section className="body-container l2-bg projects">
          <div className="header-row">
            <SearchInput placeholder={intl.get('app.search')} onChange={handleSearchChange} className="ms-2 me-4" />
            <Icons.ListIcon
              className={viewStyle === LIST_VIEW ? 'selected me-1 clickable' : 'unselected me-1 clickable'}
              onClick={() => setLocalViewStyle(LIST_VIEW)}
            />
            <Icons.BlockIcon
              className={viewStyle === CARD_VIEW ? 'selected clickable' : 'unselected clickable'}
              onClick={() => setLocalViewStyle(CARD_VIEW)}
            />
          </div>
          {viewStyle === CARD_VIEW && <div className="card-view">{getProjectCards()}</div>}
          {viewStyle === LIST_VIEW && statsRef.current && <div className="list-view">{getProjects()}</div>}
        </section>
      )}
      {projectId && (
        <section className="app-body projects">
          <ProjectPage active={active} />
        </section>
      )}
    </>
  );
};
