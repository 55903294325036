export const DEFAULT_PAGE_SIZE = 10;

// From ResponseSet.java
export const RESPONSE_SET_TYPE = {
  open: 'open',
  matrix: 'matrix',
  multi: 'multi',
  ranked: 'ranked',
  thirdparty: 'thirdparty',
  virtualFocusGroup: 'virtualFocusGroup',
  // These are not from ResponseSet.java but are here for convenience.
  conceptRotation: 'conceptRotation',
  page: 'page'
};

// from MediaType.java
export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  webcontent: 'WebContent',
  thirdParty: 'ThirdParty'
};

export const isBipolar = responseSet => {
  return responseSet && responseSet.options.find(opt => opt.type === 'bipolar') !== undefined;
};
