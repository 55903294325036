import React, { useEffect } from 'react';
import { matchPath } from 'react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import { auth } from '../../api/Auth';
import { fetchPanels } from '../../api/panelsApi';
import { fetchSessionUser } from '../../api/userApi';
import { routes } from '../../router/routes';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import { Loader } from 'webapp-common';
import { PageHeader } from '../pages/pageHeader/PageHeader';
import { queryKeys, usePutAppState } from '../../customHooks/useAppState';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function getActiveRoute(location) {
  return routes.find(r =>
    Array.isArray(r.path)
      ? r.path.some(path => matchPath(location.pathname, { path }))
      : matchPath(location.pathname, { path: r.path })
  );
}

export const App = () => {
  // Calling this here keeps the user's session active
  const isAuthenticated = auth.isAuthenticated(true);

  const location = useLocation();
  const activeRoute = getActiveRoute(location);
  if (activeRoute?.authenticated && !isAuthenticated) {
    auth.logout();
  }

  const sessionUserQuery = useQuery('session-user', fetchSessionUser, { enabled: isAuthenticated });
  const { user, superUser } = sessionUserQuery.data?.payload || {};

  useEffect(() => {
    if (user) {
      user.isSuperUser = superUser; // set this for convenience
      Sentry.setUser({ email: user.email });
    }
  }, [user, superUser]);

  usePutAppState(queryKeys.sessionUser, user);

  const panelsQuery = useQuery('panels', fetchPanels, { enabled: !!user });
  const { content: panels = [] } = panelsQuery.data?.payload || {};

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <DefaultLayout header={<PageHeader isAuthenticated={isAuthenticated} user={user} panels={panels} />}>
        {(sessionUserQuery.isLoading || panelsQuery.isLoading) && <Loader spinner fullScreen />}
        <Switch>
          {routes.map(route => (
            <Route
              path={route.path}
              render={props => <route.component {...props} routes={route.routes} />}
              key={route.path}
            />
          ))}
        </Switch>
      </DefaultLayout>
    </Sentry.ErrorBoundary>
  );
};
