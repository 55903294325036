import { useEffect } from 'react';
import { useQuery } from 'react-query';

export const queryKeys = {
  selectedPanel: 'selectedPanel',
  sessionUser: 'sessionUser'
};

export const usePutAppState = (key, value) => {
  const { refetch } = useQuery(key, () => value);

  useEffect(() => {
    refetch();
  }, [key, value]);
};

export const useGetAppState = key => {
  return useQuery(key).data;
};
