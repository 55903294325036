import React from 'react';
import intl from 'react-intl-universal';
import appConfig from '../../../appConfig';
import { NavTab } from 'webapp-common';

export const ProjectsLeftNav = props => {
  const { activeTab } = props;

  return (
    <section className="left-nav">
      <NavTab
        icon="fa-bolt"
        iconStyle="solid"
        label={intl.get('app.active')}
        active={activeTab === 'active'}
        to={appConfig.activeProjectsPath}
      />
      <NavTab
        icon="fa-times-circle"
        iconStyle="solid"
        label={intl.get('app.closed')}
        active={activeTab === 'closed'}
        to={appConfig.closedProjectsPath}
      />
    </section>
  );
};
