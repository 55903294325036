import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import { DEFAULT_PAGE_SIZE } from '../../../../../util/joinerUtil';
import { InvokeTable } from 'webapp-common';

const reducer = (state, payload) => ({ ...state, ...payload });

export function OpenTextResponses(props) {
  const { joinerId, summary, getPagedOrSortedResponses } = props;

  const [state, setState] = useReducer(reducer, {
    pageNumber: 1,
    tableData: null
  });

  const sort = (sortField, sortOrder) => {
    getPagedOrSortedResponses(joinerId, 1, DEFAULT_PAGE_SIZE, sortField, sortOrder);
  };

  const paginate = (pageNumber, sizePerPage, sortField, sortOrder) => {
    getPagedOrSortedResponses(joinerId, pageNumber, sizePerPage, sortField, sortOrder);
    setState({ pageNumber });
  };

  const getColumns = () => {
    return [
      {
        dataField: 'userId',
        text: intl.get('app.userId'),
        classes: 'text-truncate',
        headerClasses: 'header right-border',
        sort: true
      },
      {
        dataField: 'response',
        text: intl.get('app.response'),
        classes: 'text-truncate',
        headerClasses: 'header right-border',
        sort: true
      },
      {
        dataField: 'wordCount',
        text: intl.get('app.wordCount'),
        classes: 'text-truncate',
        headerClasses: 'header right-border',
        sort: true
      }
    ];
  };

  if (summary?.pagedListOpenText && !isEqual(state.tableData, summary.pagedListOpenText)) {
    setState({
      tableData: cloneDeep(summary.pagedListOpenText)
    });
  }

  return (
    <>
      {summary && (
        <InvokeTable
          keyField="userId"
          columns={getColumns()}
          pagedList={state.tableData}
          classes="response-table invoke-table"
          sort={sort}
          enablePagination={summary.totalResponses > DEFAULT_PAGE_SIZE}
          paginate={paginate}
        />
      )}
    </>
  );
}
