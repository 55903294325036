import { fetcher } from 'webapp-common';

const PP_USERS_API = '/a/api/panelPortalUsers';
const USERS_API = '/a/api/users';

export function getUsers(params) {
  return fetcher.post(PP_USERS_API, params);
}

export function createUser(user) {
  return fetcher.post(USERS_API, user);
}

export function updateUser(user) {
  return fetcher.put(USERS_API, user);
}
