import { fetcher } from 'webapp-common';

const PROJECT_PARTICIPANTS = '/a/api/participantList/metadata_collection';

export const fetchProjectParticipants = (collectionId, options) => {
  const params =
    (options && {
      pageNumber: options.page || 1,
      pageSize: options.sizePerPage || 10,
      sortBy: options.sortField || '',
      sortOrder: options.sortOrder || 'asc',
      activeOnly: options.activeOnly,
      searchString: options.searchString || '',
      panelId: options.panelId || ''
    }) ||
    {};
  return fetcher.get(`${PROJECT_PARTICIPANTS}/${collectionId}/participant`, params);
};
