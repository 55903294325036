import React, { useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';
import intl from 'react-intl-universal';
import { toast, Loader } from 'webapp-common';
import { fetchSession, fetchSurvey, fetchResponseData } from '../../../../../api/projectsApi';
import { queryKeys, useGetAppState } from '../../../../../customHooks/useAppState';
import { ProjectResponses as ResponseDetails } from './ProjectResponses';

const reducer = (state, payload) => ({ ...state, ...payload });

const ProjectResponses = props => {
  const { responseRates, sessionId } = props;

  const [params, setParams] = useReducer(reducer, {
    screenerJoiners: [], // List of selected joiners
    sessionId,
    qualifiedStatus: 'ALL'
  });

  const selectedPanel = useGetAppState(queryKeys.selectedPanel);
  const selectedPanelId = selectedPanel?.panelId;

  useEffect(() => {
    if (selectedPanelId) {
      setParams({
        filter: {
          expression: `P."source":A."${selectedPanelId}";`,
          type: 'GENERAL_FILTER'
        }
      });
    }
  }, [selectedPanelId]);

  if (sessionId && sessionId !== params.sessionId) {
    setParams({ sessionId });
  }

  const sessionData = useQuery(['fetch-session', sessionId], () => fetchSession(sessionId), {
    enabled: !!sessionId,
    onError: () => toast.error({ text: intl.get('app.fetchProjectError') })
  });

  const session = sessionData.data?.payload || {};
  const { surveyId } = session;
  const surveyData = useQuery(['fetch-survey', surveyId], () => fetchSurvey(surveyId), {
    enabled: !!surveyId,
    onError: () => toast.error({ text: intl.get('app.responses.fetchJoinersError') })
  });

  const survey = surveyData.data?.payload || {};

  const partDataSummariesData = useQuery(['get-response-data', params], () => fetchResponseData(params), {
    enabled: !!params.sessionId && !!params.filter,
    keepPreviousData: true,
    onError: () => toast.error({ text: intl.get('app.fetchResponseDataError') })
  });

  const partDataSummaries = partDataSummariesData.data?.partDataSummaries?.responses;
  const partDataTotals = partDataSummariesData.data?.partDataTotals?.answers || [];

  return (
    <>
      {(sessionData.isLoading || surveyData.isLoading || partDataSummariesData.isLoading) && (
        <Loader fullScreen spinner />
      )}
      <ResponseDetails
        joiners={survey.joiners}
        screenerJoiners={params.screenerJoiners}
        setParams={setParams}
        responseRates={responseRates?.panelStats.find(p => p.panelId === selectedPanelId) || {}}
        screenersAnswersSummaries={partDataSummaries}
        screenersAnswersTotal={partDataTotals}
      />
    </>
  );
};

export default ProjectResponses;
