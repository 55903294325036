import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { isEqual } from 'lodash';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { InvokeTable } from 'webapp-common';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.summary, nextProps.summary)) {
    return false;
  }
  return true;
};

export const ChoiceResponses = memo(props => {
  const getChoiceAnswer = (choice, summary) => {
    if (!summary.mapAnswerValueToCount[choice] || !summary.totalResponses) {
      return '0%';
    }
    return Math.round((summary.mapAnswerValueToCount[choice] / summary.totalResponses) * 100) + '%';
  };

  const getTableData = summary => {
    const choices = props.joiner?.def?.responseSet.choices;
    const rowData = choices
      .filter(choice => !choice.value.disable)
      .map(choice => {
        const { value } = choice;
        const { imageStim } = value;
        const question = (imageStim && (imageStim.caption || imageStim.media.title)) || value.value;
        return {
          id: choice.id,
          value: question,
          src: imageStim && mediaUtil.getMediaUrl(imageStim.media),
          count: getChoiceAnswer(question, summary)
        };
      });
    return { content: rowData };
  };

  const formatQuestionCell = (text, row) => {
    return (
      <span>
        {text}
        {row.src && <img className="image-option ms-3" src={row.src} alt={row.value} key={`${row.id}-it`} />}
      </span>
    );
  };

  const getColumns = () => {
    return [
      {
        dataField: 'value',
        text: intl.get('app.answer'),
        classes: 'text-truncate',
        headerClasses: 'header right-border',
        formatter: formatQuestionCell
      },
      {
        dataField: 'count',
        text: intl.get('app.responses'),
        align: 'right',
        headerClasses: 'header'
      }
    ];
  };

  const tableData = props.summary && getTableData(props.summary);
  return (
    <React.Fragment>
      {tableData && (
        <InvokeTable keyField="id" columns={getColumns()} pagedList={tableData} classes="response-table invoke-table" />
      )}
    </React.Fragment>
  );
}, skipUpdate);
