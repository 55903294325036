import React, { useReducer } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import intl from 'react-intl-universal';
import { FlexRow } from 'webapp-common';
import { RESPONSE_SET_TYPE, DEFAULT_PAGE_SIZE } from '../../../../../util/joinerUtil';
import { ChoiceResponses } from './ChoiceResponses';
import { MatrixResponses } from './MatrixResponses';
import { OpenTextResponses } from './OpenTextResponses';

import './ProjectResponses.css';

const flattenArray = (answers, key, value) => {
  return answers
    ? answers.reduce((answer, current) => {
        answer[current[key]] = value ? current[value] : current;
        return answer;
      }, {})
    : {};
};

const getPercentAnswered = (joiner, answers, totalResponses) => {
  if (totalResponses && answers[joiner.id]) {
    return Math.round((answers[joiner.id] / totalResponses) * 100) + '%';
  }
  return 'N/A';
};

const reducer = (state, payload) => ({ ...state, ...payload });

export const ProjectResponses = props => {
  const { joiners, screenerJoiners, responseRates, screenersAnswersTotal, screenersAnswersSummaries, setParams } =
    props;

  const totalResponses = responseRates.attempted || 0;

  const [state, setState] = useReducer(reducer, {
    questionJoiners: []
  });

  if (joiners && state.questionJoiners.length === 0) {
    setState({
      questionJoiners: joiners
    });
  }

  const toggleDetails = index => {
    const questionJoiners = cloneDeep(state.questionJoiners);
    questionJoiners[index].showJoinerDetail = !questionJoiners[index].showJoinerDetail;
    const updatedScreenerJoiners = questionJoiners.reduce((selected, joiner) => {
      if (joiner.showJoinerDetail) {
        joiner.def.responseSet.type === RESPONSE_SET_TYPE.open
          ? selected.push({ joinerId: joiner.id, pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE })
          : selected.push({ joinerId: joiner.id });
      }
      return selected;
    }, []);
    setState({ questionJoiners });
    setParams({ screenerJoiners: updatedScreenerJoiners });
  };

  const getPagedOrSortedResponses = (joinerId, pageNumber, pageSize, sortField, sortOrder) => {
    const screenerJoinersCopy = cloneDeep(screenerJoiners);
    screenerJoinersCopy.forEach(sj => {
      if (sj.joinerId === joinerId) {
        sj.pageNumber = pageNumber;
        sj.pageSize = pageSize;
        sj.sortBy = sortField;
        sj.sortOrder = sortOrder;
      }
    });
    setParams({ screenerJoiners: screenerJoinersCopy });
  };

  const getQuestionJoiner = () => {
    const answersTotal = screenersAnswersTotal || [];
    const answers = flattenArray(answersTotal, 'joinerId', 'totalResponses');
    const flattenedAnswersSummaries = flattenArray(screenersAnswersSummaries, 'joinerRowColId');

    return state.questionJoiners.map((joiner, index) => {
      const type = joiner.def?.responseSet.type || joiner.stim?.type;
      const joinerType = type && intl.get(`app.questionType.${type}`);
      const showJoinerDetail = joiner.showJoinerDetail && !joiner.stimOnly;
      return (
        <React.Fragment key={joiner.id}>
          {!joiner.stimOnly && !joiner.container && !joiner.emailCollector && (
            <FlexRow
              onClick={() => toggleDetails(index)}
              className={joiner.stimOnly ? 'not-allowed row-head' : 'clickable row-head'}
            >
              <span>
                <label className="me-2">{showJoinerDetail ? '-' : '+'}</label>
                <label>{`${joiner.questionNum}. ${joiner.researchPrompt}`}</label>
              </span>
              <span>
                <div className="me-5 inline-block response-stats">{joinerType}</div>
                <div className="inline-block response-stats">
                  {!isEmpty(answers) &&
                    answers[joiner.id] &&
                    `${answers[joiner.id]} - ${getPercentAnswered(joiner, answers, totalResponses)}`}
                </div>
                <span />
              </span>
            </FlexRow>
          )}
          {showJoinerDetail && type === RESPONSE_SET_TYPE.matrix && (
            <MatrixResponses joiner={joiner} summary={flattenedAnswersSummaries[joiner.id]} />
          )}
          {showJoinerDetail && type === RESPONSE_SET_TYPE.multi && (
            <ChoiceResponses joiner={joiner} summary={flattenedAnswersSummaries[joiner.id]} />
          )}
          {showJoinerDetail && type === RESPONSE_SET_TYPE.open && (
            <OpenTextResponses
              joinerId={joiner.id}
              summary={flattenedAnswersSummaries[joiner.id]}
              getPagedOrSortedResponses={getPagedOrSortedResponses}
            />
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <div>
        <label style={{ marginLeft: '0.5rem', fontWeight: '600' }}>
          {`${totalResponses || ''} ${intl.get('app.responses')}`}:
        </label>
      </div>
      <div className="screener-responses">{getQuestionJoiner()}</div>
    </>
  );
};
