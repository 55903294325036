import React from 'react';
import { LoginPage } from '../containers/pages/loginPage/LoginPage';
import { CallbackPage } from '../containers/pages/callbackPage/CallbackPage';
import { ProjectsHome } from '../containers/pages/projects/ProjectsHome';
import { Admin } from '../containers/pages/admin/Admin';

export const routes = [
  {
    path: '/login',
    authenticated: false,
    component: () => <LoginPage />
  },
  {
    path: '/logout',
    authenticated: false,
    component: () => <LoginPage doLogout />
  },
  {
    path: '/callback',
    authenticated: false,
    component: props => <CallbackPage history={props.history} />
  },
  {
    path: '/projects/:tab?/:id?/:subtab?',
    authenticated: true,
    component: props => <ProjectsHome routeProps={props} />
  },
  {
    path: '/admin/:tab?',
    authenticated: true,
    component: props => <Admin routeProps={props} />
  },
  {
    path: '(.*)',
    authenticated: false,
    component: () => <h1 style={{ textAlign: 'center', marginTop: '1rem' }}>404 Not Found</h1>
  }
];
