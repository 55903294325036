import React from 'react';
import intl from 'react-intl-universal';
import { queryKeys, useGetAppState } from '../../../../customHooks/useAppState';
import { projectUtil } from '../../../../util/projectUtil';
import { IrLabel } from '../projectCard/IrLabel';
import { CardStats } from '../projectCard/CardStats';
import { Icons } from '../../../../components/icons/Icons';

export const ProjectSummary = props => {
  const { responseRates = {} } = props;
  const { panelQuotas = [], panelStats = [], segmentCategoryTotals = [] } = responseRates;
  const panelId = useGetAppState(queryKeys.selectedPanel)?.panelId;
  const stats = (panelId && projectUtil.getDataForPanel(panelStats, panelId)) || {};
  const quotas = (panelId && projectUtil.getDataForPanel(panelQuotas, panelId)) || {};
  const { completed, overQuota, terminated, incidenceRate = 0 } = stats;
  const labelColor = projectUtil.getCardColor(incidenceRate, quotas.expectedIR);

  const showSegements = (segs, enabled) => {
    return segs.map((seg, i) => {
      const lock = (seg.qualified < seg.maxQuota && <Icons.OpenLockIcon className="lock" />) || (
        <Icons.BlackLockIcon className="lock" />
      );
      return (
        <div className="ms-3" style={{ color: 'var(--gray-800)' }} key={`seg-${i}`}>
          {enabled && <span>{lock}</span>}
          <div style={{ width: '10rem', display: 'inline-block' }}>{seg.segmentName}</div>
          <div style={{ width: '3rem', textAlign: 'right', display: 'inline-block' }}>{seg.qualified}</div>
        </div>
      );
    });
  };

  const segments = segmentCategoryTotals
    .filter(sct => sct.panelSegmentTotals[panelId]?.length > 0)
    .map((sct, i) => (
      <div className="segment mb-3 me-2" key={`${sct.segmentCategoryName}-${i}`}>
        <p className="segment-name ms-2">{sct.segmentCategoryName}</p>
        {showSegements(sct.panelSegmentTotals[panelId], sct.quotaEnabled)}
      </div>
    ));

  return (
    <div className="project-summary">
      <div className="summary">
        <IrLabel
          backgroundColor={labelColor}
          rate={incidenceRate}
          size="1.5rem"
          target={quotas.expectedIR}
          targetSize="50%"
        />
        <CardStats completed={completed} terminated={terminated} overQuota={overQuota} className="ms-5 summary-stats" />
      </div>
      {segments?.length > 0 && (
        <div className="segments mt-5">
          <p className="ms-2">
            <strong style={{ color: 'var(--gray-900)' }}>{intl.get('app.segments')}:</strong>
          </p>
          {segments}
        </div>
      )}
    </div>
  );
};
