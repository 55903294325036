import React from 'react';
import intl from 'react-intl-universal';
import { projectUtil } from '../../../../util/projectUtil';
import { IrLabel } from './IrLabel';
import { CardStats } from './CardStats';

export const ProjectCard = props => {
  const { name, status, displayTime, responseRates = {}, panelId } = props;
  const { panelStats = [], panelQuotas = [] } = responseRates;
  const stats = projectUtil.getDataForPanel(panelStats, panelId);
  const quotas = projectUtil.getDataForPanel(panelQuotas, panelId);
  const { completed, overQuota, terminated, incidenceRate = 0 } = stats;
  const { expectedIR } = quotas;

  const backgroundColor = projectUtil.getCardColor(incidenceRate, expectedIR);

  return (
    <div className="card" onClick={props.gotoUrl}>
      <div className="card-heading">
        <label className="label text-ellipsis strong" title={name}>
          {name}
        </label>
        <label className="status">{intl.get(`app.session.state.${status}`).toUpperCase()}</label>
      </div>
      <div className="ir-percent">
        <IrLabel backgroundColor={backgroundColor} rate={incidenceRate} target={expectedIR} targetSize="75%" />
        <span className="ms-2" style={{ fontSize: '90%', fontFamily: 'inherit' }}>
          {displayTime}
        </span>
      </div>
      <CardStats completed={completed} terminated={terminated} overQuota={overQuota} />
    </div>
  );
};
