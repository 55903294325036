import React from 'react';
import { accessHandler } from 'webapp-common';
import { queryKeys, useGetAppState } from '../../../customHooks/useAppState';
import { AdminLeftNav } from './AdminLeftNav';
import { AdminUsers } from './users/AdminUsers';
import { AdminPanels } from './panels/AdminPanels';

const USERS_TAB = 'users';
const PANELS_TAB = 'panels';

export const Admin = props => {
  const activeTab = props.routeProps.match.params.tab || USERS_TAB;

  const sessionUser = useGetAppState(queryKeys.sessionUser);

  const accessCheck = accessHandler.checkAccess(sessionUser?.isSuperUser);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <section className="app-body">
      <AdminLeftNav activeTab={activeTab} />
      {activeTab === USERS_TAB && <AdminUsers />}
      {activeTab === PANELS_TAB && <AdminPanels />}
    </section>
  );
};
