import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Container } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';

import './DefaultLayout.css';

const DefaultLayout = props => {
  return (
    <div className="panel-portal-app">
      {props.header}
      <Container>{props.children}</Container>
      <ToastContainer autoClose={4000} />
    </div>
  );
};

export default DefaultLayout;
