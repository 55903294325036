import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { isBipolar } from '../../../../../util/joinerUtil';
import { InvokeTable } from 'webapp-common';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.summary, nextProps.summary)) {
    return false;
  }
  return true;
};

export const MatrixResponses = memo(props => {
  const { joiner, summary } = props;

  const getMatrixAnswerPercent = (rowId, columnId) => {
    if (!summary) {
      return 0;
    }
    const { mapIdToSummary, totalResponses } = summary;
    return Math.round((mapIdToSummary[rowId]['mapAnswerValueToCount'][columnId] / totalResponses) * 100);
  };

  const getColumns = (columnData, rowId) => {
    return columnData.reduce((col, { id, value }, index) => {
      const percentage = getMatrixAnswerPercent(rowId, id);
      col[id] = value.value;
      col[`${value.value}-${index}`] = typeof percentage === 'number' ? percentage + '%' : 'N/A';
      return col;
    }, {});
  };

  const getTableData = () => {
    const columnData = joiner.def?.responseSet.entries.columnData.columns;
    const responseSet = joiner.def?.responseSet;
    const bipolar = isBipolar(responseSet);
    const rows = bipolar ? joiner.def?.responseSet.entries.bipolarRows : joiner.def?.responseSet.entries.rows;
    const rowData = rows.map(row => {
      const media = row.value?.imageStim?.media;
      return {
        id: row.id,
        value: row.value.type === 'imagelabel' ? row.value.imageStim.caption : row.value.value,
        columns: getColumns(columnData, row.id),
        src: mediaUtil.getMediaUrl(media),
        value2: row.value.value2
      };
    });
    return { content: rowData };
  };

  const formatQuestionCell = (text, row) => {
    return (
      <span>
        {text}
        {row.src && <img className="image-option ms-3" src={row.src} alt={row.value} key={`${row.id}-it`} />}
      </span>
    );
  };

  const getMatrixColumns = () => {
    let columns = [
      {
        dataField: 'value',
        text: '',
        classes: 'text-truncate',
        headerClasses: 'header right-border',
        formatter: formatQuestionCell
      }
    ];
    const columnData = joiner.def?.responseSet.entries.columnData.columns;
    const matrixColumns = columnData.map((column, index) => {
      return {
        dataField: `columns.${column.value.value}-${index}`,
        text: `${column.value.value}`,
        classes: 'text-truncate',
        headerClasses: 'header right-border',
        align: 'right'
      };
    });
    columns = columns.concat(matrixColumns);
    if (isBipolar(joiner.def?.responseSet)) {
      const columnRight = [
        {
          dataField: 'value2',
          text: '',
          classes: 'text-truncate',
          headerClasses: 'header right-border',
          formatter: formatQuestionCell
        }
      ];
      columns = columns.concat(columnRight);
    }
    return columns;
  };

  const tableData = getTableData();
  return (
    <>
      {tableData && (
        <InvokeTable
          keyField="id"
          columns={getMatrixColumns()}
          pagedList={tableData}
          classes="response-table invoke-table"
        />
      )}
    </>
  );
}, skipUpdate);
